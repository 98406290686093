import { baseApi } from '../../../common/rtk-query/base.api'
import { ProductsWithTotalCountAdminDto } from '../../modeling/data/dto/products-with-total-count-admin.dto'
import { ProductsListParamsDto } from './dto/products-list-params.dto'
import { ProductsWithTotalCountDto } from './dto/products-with-total-count.dto'

const PRODUCTS_URL = 'products'

const productsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllProducts: build.query<ProductsWithTotalCountAdminDto, ProductsListParamsDto>({
      queryFn: async (productsListParamsDto, api, _, baseQuery) => {
        const queryResponse = await baseQuery({
          url: `${PRODUCTS_URL}/all`,
          method: 'GET',
          params: {
            ...productsListParamsDto,
            filters: JSON.stringify(productsListParamsDto.filters),
          },
        })
        if (queryResponse.error) {
          return { error: queryResponse.error }
        }
        return { data: queryResponse.data as ProductsWithTotalCountAdminDto }
      },
      providesTags: ['Products'],
    }),
    getProducts: build.query<ProductsWithTotalCountDto, ProductsListParamsDto>({
      queryFn: async (productsListParamsDto, api, _, baseQuery) => {
        const queryResponse = await baseQuery({
          url: `${PRODUCTS_URL}/my`,
          method: 'GET',
          params: {
            ...productsListParamsDto,
            filters: JSON.stringify(productsListParamsDto.filters),
          },
        })
        if (queryResponse.error) {
          return { error: queryResponse.error }
        }
        return { data: queryResponse.data as ProductsWithTotalCountDto }
      },
      providesTags: ['Products'],
    }),
    getProductsForUser: build.query<
      ProductsWithTotalCountDto,
      { id: string | null; params?: ProductsListParamsDto }
    >({
      queryFn: async ({ id, params }, api, _, baseQuery) => {
        const queryResponse = await baseQuery({
          url: `${PRODUCTS_URL}/forUser/${id}`,
          method: 'GET',
          params: {
            ...params,
            filters: JSON.stringify(params?.filters),
          },
        })

        if (queryResponse.error) {
          return { error: queryResponse.error }
        }

        return { data: queryResponse.data as ProductsWithTotalCountDto }
      },
      providesTags: ['Products'],
    }),
    deleteProduct: build.mutation<void, string>({
      query: (id) => ({
        url: `${PRODUCTS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Products'],
    }),
  }),
})

export const {
  useGetProductsQuery,
  useDeleteProductMutation,
  useGetAllProductsQuery,
  useGetProductsForUserQuery,
} = productsApi
