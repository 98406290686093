import { useMediaQuery } from 'react-responsive'
import s from './HeaderUserInfo.module.scss'
import arrowDownSVG from '../../../../assets/arrow__down.svg'
import { Link } from 'react-router-dom'
import { useGetUserQuery } from '../../../../auth/user.api'
import { ROUTES } from '../../../router/routes'
import { Fade, IconButton, Menu, MenuItem } from '@mui/material'
import { MouseEvent, useMemo, useState } from 'react'
import { Role } from '../../../../auth/dto/role.enum'

export function HeaderUserInfo() {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const { data: user } = useGetUserQuery()

  const [menuItemAnchor, setMenuItemAnchor] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(menuItemAnchor)

  const handleMenuOpen = (event: MouseEvent<HTMLDivElement>) => {
    if (isMenuOpen) {
      setMenuItemAnchor(null)
    } else {
      setMenuItemAnchor(event.currentTarget)
    }
  }

  const handleClose = () => {
    setMenuItemAnchor(null)
  }

  const userName = useMemo(() => {
    if (user) {
      let name = user.name
      switch (user.role) {
        case Role.admin:
          name += ' (admin)'
          break
        case Role.modeler:
          name += ' (modeler)'
          break
        default:
          break
      }
      return name
    }
    return ''
  }, [user])

  return (
    <>
      {user && (
        <div onClick={handleMenuOpen} className={s.HeaderUserInfo}>
          <div className={s.circle}>
            <div className={s.symbol}>{user.name.charAt(0)}</div>
          </div>
          <div className={s.profile__name}>{userName}</div>
          {isDesktop && (
            <div className={s.arrow}>
              <IconButton size="small">
                <img src={arrowDownSVG} alt="Open Menu" />
              </IconButton>
              <Menu
                className="product-row-menu"
                anchorEl={menuItemAnchor}
                open={isMenuOpen}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <Link to={ROUTES.profile.path}>
                  <MenuItem className="row-menu-item">Profile</MenuItem>
                </Link>
                {/* <Link to={ROUTES.help.path}>
                  <MenuItem className="row-menu-item">Help</MenuItem>
                </Link> */}
                <Link to={ROUTES.logout.path}>
                  <MenuItem className="logout-item">Logout</MenuItem>
                </Link>
              </Menu>
            </div>
          )}
        </div>
      )}
    </>
  )
}
