import { Navigate } from 'react-router-dom'
import { authApi } from '../../auth/auth.api'
import { LocalStorageUtils } from '../../common/local-storage-utils'
import { ROUTES } from '../../common/router/routes'
import { useEffect } from 'react'

export const LogoutPage = () => {
  const [logout, { isLoading }] = authApi.useLazyLogoutQuery()

  useEffect(() => {
    if (LocalStorageUtils.getAccessToken()) {
      logout()
      LocalStorageUtils.clearAccessToken()
    }
  }, [])

  return isLoading ? null : <Navigate to={ROUTES.login.path} />
}
